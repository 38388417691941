import listEmpty from "../../assets/list-empty.svg";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {borderStyles, boxStyles} from "./helpers.tsx";
import {clsxMerge} from "../../utilis/classNameUtils.ts";
import {useSelector} from "react-redux";
import {selectTheme} from "../../store/features/ThemeSwitcher/themeSwitcherSlice";
import {useState} from "react";
import CallDetails from "./CallDetails";
import {Call} from "../../store/features/calls/callsService";
import CallRow from "./CallRow";


interface MissedCallsProps {
    groupedCalls: { [key: string]: Call[] };
}

const MissedCalls = ({groupedCalls}: MissedCallsProps) => {
    const [selectedCall, setSelectedCall] = useState();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const theme = useSelector(selectTheme);

    const handleCallClick = (call: any) => {
        setSelectedCall(call == selectedCall ? null : call);
    };
    if (!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    return (

        <div className={`h-[calc(100vh-250px)] overflow-y-auto border-t border-black`}>
            <div className={clsxMerge(borderStyles({theme}), boxStyles({theme}))}>

                {sortedGroups.map(date => (
                    <div className="" key={date}>

                        {groupedCalls[date].map((call: Call) => (
                          <CallRow key={call.callId} call={call} isMobile={isMobile} handleCallClick={handleCallClick} selectedCall={selectedCall}/>
                        ))}
                    </div>
                ))}
                {sortedGroups.length === 0 &&
                    <div className='flex text-center  justify-center '>
                        <div className='w-[450px]  m-14'>
                            <div className='flex  justify-center w-full'>
                                <img className='m-4' src={listEmpty}/>
                            </div>

                            <h1 className='font-bold'>
                                No Missed Calls!
                            </h1>
                            <div className='mb-6 mt-2'>
                                There are no missed calls.
                            </div>
                        </div>

                    </div>


                }
            </div>
            <div>
                {selectedCall &&
                    <CallDetails selectedCall={selectedCall} theme={theme}/>

                }
            </div>
        </div>
    );
};
export default MissedCalls;
