import { createApi } from '@reduxjs/toolkit/query/react';
import { ColorKey } from '../../../utilis/types/Types';
import {baseQuery} from "../../baseQuery";
import {User} from "../Users/userService";
import {Group} from "../groups/groupsService";

export interface ContactSelectItem {
    contactId: number;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    sharedFor: SharedItem[];
    iconColor?: ColorKey;
    sharedForUsers: User[];
    sharedForGroups: Group[];
    availability: string;
}

export interface ContactList {
    contacts: ContactSelectItem[];
    totalCount: number;
}

export type SharedItem = {
    userId?: number;
    groupId?: number;
    iconColor: ColorKey;
    name: string;
    fullName?: string;
};

export interface Contact {
    contactId: number;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    availability: string;
    createdBy: string;
    phoneNumbers: { msisdn: number; main: boolean }[];
    sharedForUsers: User[];
    sharedForGroups: Group[];
    createdAt: string;
    iconColor?: ColorKey;
    owner: {
        userId: number;
        firstName: string;
        lastName: string;
        iconColor: ColorKey;
    };
}

export interface ContactDTO {
    contactId?: number;
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    availability: string;
    createdBy: string;
    phoneNumbers: { msisdn: number; main: boolean }[];
    sharedForUsers: User[];
    sharedForGroups: Group[];
    iconColor?: ColorKey;
}


interface ParamsProps {
    phrase?: string;
    order?: string;
    sortBy?: string;
    sharedForUsers?: number[];
    sharedForGroups?: number[];
    lastContactId?: number;
    lastLastName?: string
    lastFirstName?: string;
    lastMsisdn?: number;
    firstName?: string;
    userId: string;
}
export interface ContactListItem {
    contactId: number;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    sharedForUsers: User[];
    sharedForGroups:Group[];
    availability: string;
}

type ContactIdentity = {
    contactId: number;
    firstName: string;
    lastName: string;
    phoneNumber: number;
};

type ContactResponse = {
    primary: ContactIdentity;
    duplicated: ContactIdentity[];
}[];
export const contactsApi = createApi({
    reducerPath: 'contactsApi',
    baseQuery,
    tagTypes: ['Contact'],
    endpoints: (builder) => ({
        getContacts: builder.query<ContactList, ParamsProps>({
            query: ({ phrase, lastContactId, sharedForUsers, sharedForGroups, order, sortBy, lastLastName, lastFirstName, lastMsisdn, userId }) => ({
                url: `/contact/list/${userId}`,
                params: { phrase, lastContactId, sharedForUsers, sharedForGroups, order, sortBy, lastLastName, lastFirstName, lastMsisdn },
            }),
            transformResponse: (response: ContactSelectItem[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    contacts: response || [],
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0,
                };
            },
            providesTags: ['Contact'],
        }),
        getContactById: builder.query<Contact, number>({
            query: (contactId) => `/contact/${contactId}`,
            providesTags: (contact) => contact ? [{ type: 'Contact', id: contact.contactId }] : [],
        }),
        addContact: builder.mutation<void, ContactDTO>({
            query: (newContact) => ({
                url: '/contact',
                method: 'POST',
                body: newContact,
            }),
            invalidatesTags: ['Contact'],
        }),
        updateContact: builder.mutation<ContactDTO, ContactDTO>({
            query: (contactData) => ({
                url: `/contact/${contactData.contactId}`,
                method: 'PATCH',
                body: contactData,
            }),
            invalidatesTags: (contactData) => [{ type: 'Contact', id: contactData?.contactId }],
        }),
        deleteContactById: builder.mutation<number, number>({
            query: (contactId) => ({
                url: `/contact/${contactId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (contactId) => [{ type: 'Contact', id: contactId }],
        }),
        getContactByPhoneNumber: builder.query<ContactResponse, {msisdn: string, userId: string | null}>({
            query: ({msisdn, userId}) => ({
                url: `/contact/calls/${userId}`,
                params: {phrase: msisdn},
            }),
        }),
    }),
});


export const {
    useGetContactsQuery,
    useGetContactByIdQuery,
    useAddContactMutation,
    useUpdateContactMutation,
    useDeleteContactByIdMutation,
    useGetContactByPhoneNumberQuery,
    useLazyGetContactByPhoneNumberQuery,
} = contactsApi;
