import { call } from "../components/Dialer/services/sipService";
import {RootState, useAppDispatch} from "../store/store";
import {
    getSelectedSpeaker,
    selectSelectedPhoneNumber,
    setCallFrom,
    setCallState, setPhoneNumber
} from "../store/features/calls/dialerSlice";
import {CallStateEnum} from "../components/Dialer/Dialer";
import {useCallOptions} from "../components/Dialer/hooks/useCallOptions";
import {useSelector} from "react-redux";
import {selectUserId} from "../store/features/Auth/authSlice";
import ringbackSound from '../assets/sounds/ringback.mp3';
import { useRef, useEffect } from "react";
import {useLazyGetContactByPhoneNumberQuery} from "../store/features/Contacts/contactsService";
import {showToast} from "../store/features/Notifications/notificationsSlice";

interface CallOptions {
    msisdn: string;
    fromName?: string;
    isInternal?: boolean;

}
export const useCallService = () => {
    const dispatch = useAppDispatch();
    const audioDevice = useSelector(getSelectedSpeaker);
    const callState = useSelector((state: RootState) => state.dialer.callState);
    const options = useCallOptions();
    const selectedPhoneNumber = useSelector(selectSelectedPhoneNumber);
    const userId = useSelector(selectUserId);
    const ringbackSoundRef = useRef(new Audio(ringbackSound));
    const [fetchContact] =
        useLazyGetContactByPhoneNumberQuery();
    useEffect(() => {
        if (callState !== CallStateEnum.CONNECTING) {
            ringbackSoundRef.current.pause();
            ringbackSoundRef.current.currentTime = 0;
        }
    }, [callState]);

    const makeCall = async ({ msisdn, fromName = '', isInternal = false }: CallOptions) => {
        if (!msisdn) return;
        if (!selectedPhoneNumber && !isInternal){
            dispatch(showToast({title: 'The call cannot be completed', description: 'No phone number has been assigned. Only internal calls are available.', type: 'error' }));
            return;
        }
        const sanitizedMsisdn = isInternal ? msisdn : msisdn.replace(/\+/g, '');
        let callFrom = fromName || msisdn;

        if (!isInternal) {
            const { data = [] } = await fetchContact({ msisdn: sanitizedMsisdn, userId });
            callFrom = data.length
                ? `${data[0]?.primary?.firstName || ''} ${data[0]?.primary?.lastName || ''}`.trim()
                : msisdn;
        }

        dispatch(setCallFrom(callFrom));
        dispatch(setCallState(CallStateEnum.CONNECTING));
        dispatch(setPhoneNumber(''));

        if (isInternal) {
            ringbackSoundRef.current.play();
        }

        call(msisdn, {
            ...options,
            fromUserName: isInternal ? userId : selectedPhoneNumber.msisdn,
        }, audioDevice);
    };

    return {
        makeCall,
    };
};
